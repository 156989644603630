<template>
    <div class="binging">
        <div class="binging-warp">
            <div style="height:180px"></div>
            <div class="binging-inp">
                <van-field v-model="tel" placeholder="请输入手机号" type="tel"/>
            </div>
            <van-button :disabled='!tel' @click="getRecognize(tel)" type="primary" block>确认绑定</van-button>
        </div>
        <van-overlay :show="show" @click="show = false">
            <div class="binging-wrapper" @click.stop>
                <div class="binging-wrapper-box">
                    <img :src="userInfo.student_avatar" alt="">
                    <div style="margin:5px 0">{{userInfo.student_name}}</div>
                    <div style="margin-bottom:10px">{{userInfo.student_cellphone}}</div>
                    <div class="binging-btn van-hairline--top">
                        <div class="van-hairline--right" @click="show=false">取消</div>
                        <div @click="comfirm(userInfo.student_cellphone)">绑定</div>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import { Field,Overlay } from 'vant'
    import { isPhone } from '@/common/validate'
    import { weWorkchatConfig } from '@/common/wx'
    export default {
        name:'binging',
        data() {
            return {
                tel: '',
                show:false,
                userInfo:{}
            }
        },
        components: {
            [Field.name]:Field,
            [Overlay.name]:Overlay,
        },
        created () {
            let that = this
            weWorkchatConfig()
            .then(res=>{
                wx.getClipboardData({
                    success: function(res) {
                        console.log(res.data)
                        if(isPhone(res.data)){
                            that.getRecognize(res.data)
                        }
                    },
                    fail: function(err) {
                        console.log(err)
                    }
                })
            })
            .catch(err=>{
                console.log(err)
            })
            // that.getRecognize('15900772021')
        },
        methods: {
            comfirm(tel) {
                let cellphone = tel?tel:this.tel
                this.$api.qywx_external_contact_bind({external_userid:this.$route.query.userid,cellphone})
                .then(res=>{
                    this.$toast('操作成功~')
                    localStorage.setItem('profile_binging',1)
                    this.$router.go(-1)
                })
            },
            getRecognize(cellphone){
                this.$api.qywx_external_contact_recognize({cellphone})
                .then(res=>{
                    if(res.data.student){
                        this.show = true
                        this.userInfo = res.data.student
                    }else{
                        this.$toast('该用户不是客户~')
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .binging{
        min-height: 100vh;
        background: #fff;
        &-warp{
            width: 80%;
            height: 100vh;
            margin: 0 auto;
        }
        &-inp{
            border-radius: 22px;
            overflow: hidden;
            margin-bottom: 20px;
            .van-cell{
                background-color:#f8f8f8;
            }
        }
        .van-button{
            border-radius: 22px;
        }
        &-wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            &-box{
                width: 250px;
                font-size: 16px;
                background-color: #fff;
                border-radius: 5px;
                text-align: center;
                img{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    margin-top: -50px;
                }
            }
        }
        &-btn{
            display: flex;
            align-items: center;
            
            div{
                flex: 1;
                height: 36px;
                text-align: center;
                line-height: 36px;
            }
        }
    }
</style>